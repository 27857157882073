import React, { useState } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  paper: {
    // maxWidth: 0,
    borderRadius: 0,
    margin: "auto",
    overflow: "hidden",
    justifyContent: 'center',
    padding: '50px',
    maxWidth: '520px'
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: "block",
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: "40px 16px",
  },
});

function Login(props) {
  const { classes, onSelectionChanges } = props;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event) => {
    if (username.toLowerCase() === 'wilfred' && password.toLowerCase() === '1234') {
      window.localStorage.setItem('loggedIn', '1');
      onSelectionChanges('Rides');

    }
  };

  return (
    <Paper className={classes.paper} variant="elevation" elevation={2}>
      <Grid item>
        <Typography component="h1" variant="h5" style={{paddingBottom: 20}}>
          Sign in
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              type="text"
              placeholder="Username"
              fullWidth
              name="username"
              variant="outlined"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              required
              autoFocus
            />
          </Grid>
          <Grid item>
            <TextField
              type="password"
              placeholder="Password"
              fullWidth
              name="password"
              variant="outlined"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="button-block"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectionChanges: PropTypes.func.isRequired
};

export default withStyles(styles)(Login);
