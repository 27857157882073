
  import React from 'react';
  import PropTypes from 'prop-types';
  import AppBar from '@material-ui/core/AppBar';
  import Toolbar from '@material-ui/core/Toolbar';
  import Typography from '@material-ui/core/Typography';
  import Paper from '@material-ui/core/Paper';
  import Grid from '@material-ui/core/Grid';
  import Button from '@material-ui/core/Button';
  import TextField from '@material-ui/core/TextField';
  import Tooltip from '@material-ui/core/Tooltip';
  import IconButton from '@material-ui/core/IconButton';
  import { withStyles } from '@material-ui/core/styles';
  import SearchIcon from '@material-ui/icons/Search';
  import RefreshIcon from '@material-ui/icons/Refresh';
  import AddIcon from '@material-ui/icons/Add';
  import clsx from 'clsx';
  import { lighten, makeStyles } from '@material-ui/core/styles';
  import Table from '@material-ui/core/Table';
  import TableBody from '@material-ui/core/TableBody';
  import TableCell from '@material-ui/core/TableCell';
  import TableContainer from '@material-ui/core/TableContainer';
  import TableHead from '@material-ui/core/TableHead';
  import TablePagination from '@material-ui/core/TablePagination';
  import TableRow from '@material-ui/core/TableRow';
  import TableSortLabel from '@material-ui/core/TableSortLabel';
  import Checkbox from '@material-ui/core/Checkbox';
  import FormControlLabel from '@material-ui/core/FormControlLabel';
  import Switch from '@material-ui/core/Switch';
  import DeleteIcon from '@material-ui/icons/Delete';
  import FilterListIcon from '@material-ui/icons/FilterList';
  import MomentUtils from '@date-io/moment';
  import EventIcon from '@material-ui/icons/Event';

  import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

  
  const styles = (theme) => ({
    paper: {
      // maxWidth: 0,
      borderRadius: 0,
      margin: 'auto',
      overflow: 'hidden',
    },
    searchBar: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: 'block',
    },
    addUser: {
      marginRight: theme.spacing(1),
    },
    contentWrapper: {
      margin: '40px 16px',
    },
    kenteken: {
      width: '100%',
      height: '24px',
      padding: '0',
      borderRadius: '4px',
      position: 'relative',
      background: 'url(./kenteken_start.jpg) no-repeat 0px 0px #f4be06',
      backgroundSize: 'auto calc(100% + 1px)',
      margin: '0',
      border: '1px solid #000',
      textAlign: 'left',
      paddingLeft: '15px',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      paddingRight: '4px',
      lineHeight: '22px'
    }
  });

  function createData(date, chauffeur, car, hours, amount, pin, rekening, ingeleverd, wassen, tanken, overige, contanteOmzet) {
    return { date, chauffeur, car, hours, amount, pin, rekening, ingeleverd, wassen, tanken, overige, contanteOmzet };
  }

  const rows = [
    createData('02-08-2020', 'Wilfred', 'HP-331-P', '9.6 uren', '€ 410,76', '€ 0', '€ 0', '€ 0', '€ 0', '€ 0', '€ 0', '€ 0'),
    createData('02-08-2020', 'Wilfred', 'HP-331-P', '4.2 uren', '€ 123,12', '€ 0', '€ 0', '€ 0', '€ 0', '€ 0', '€ 0', '€ 0'),
  ];

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    { id: 'date', numeric: false, disablePadding: false, label: 'Datum' },
    { id: 'chauffeur', numeric: false, disablePadding: false, label: 'Chauffeur' },
    { id: 'car', numeric: false, disablePadding: false, label: 'Voertuig', kenteken:true },
    { id: 'hours', numeric: true, disablePadding: false, label: 'Gewerkt' },
    { id: 'amount', numeric: true, disablePadding: false, label: 'Bedrag' },       
    { id: 'pin', numeric: true, disablePadding: false, label: 'Pin' },
    { id: 'rekening', numeric: true, disablePadding: false, label: 'Rekening' },
    { id: 'ingeleverd', numeric: true, disablePadding: false, label: 'Ingeleverd' },
    { id: 'wassen', numeric: true, disablePadding: false, label: 'Wassen' },
    { id: 'tanken', numeric: true, disablePadding: false, label: 'Tanken' },
    { id: 'overige', numeric: true, disablePadding: false, label: 'Overige' },
    { id: 'contanteOmzet', numeric: true, disablePadding: false, label: 'Contante omzet' },
  ];


  function DailyHours(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const [selectedDate, setSelectedDate] = React.useState(new Date('2020-08-02T21:11:54'));

    const handleDateChange = (date) => {
      setSelectedDate(date);
    };


    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <Paper className={classes.paper}>
        {<AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
            <Grid item>
                <EventIcon className={classes.block} color="inherit" />
              </Grid>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid item xs>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="DD-MM-yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Datum"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              <Grid item>
                <Tooltip title="Herladen">
                  <IconButton>
                    <RefreshIcon className={classes.block} color="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>}
          <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  {headCells.map((headCell, i) => (
                    <TableCell
                      key={`row_${row.id}_${headCell.id}`}
                      scope="rpw"
                      compoent="th"
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      {headCell.kenteken ? <div className={classes.kenteken}>{row[headCell.id]}</div> : row[headCell.id] }
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  DailyHours.propTypes = {
    classes: PropTypes.object.isRequired,
  };

  export default withStyles(styles)(DailyHours);