
  import React, { useState } from 'react';
  import PropTypes from 'prop-types';
  import AppBar from '@material-ui/core/AppBar';
  import Toolbar from '@material-ui/core/Toolbar';
  import Typography from '@material-ui/core/Typography';
  import Paper from '@material-ui/core/Paper';
  import Grid from '@material-ui/core/Grid';
  import Button from '@material-ui/core/Button';
  import TextField from '@material-ui/core/TextField';
  import Tooltip from '@material-ui/core/Tooltip';
  import IconButton from '@material-ui/core/IconButton';
  import { withStyles } from '@material-ui/core/styles';
  import SearchIcon from '@material-ui/icons/Search';
  import RefreshIcon from '@material-ui/icons/Refresh';
  import AddIcon from '@material-ui/icons/Add';
  import clsx from 'clsx';
  import { lighten, makeStyles } from '@material-ui/core/styles';
  import Table from '@material-ui/core/Table';
  import TableBody from '@material-ui/core/TableBody';
  import TableCell from '@material-ui/core/TableCell';
  import TableContainer from '@material-ui/core/TableContainer';
  import TableHead from '@material-ui/core/TableHead';
  import TablePagination from '@material-ui/core/TablePagination';
  import TableRow from '@material-ui/core/TableRow';
  import TableSortLabel from '@material-ui/core/TableSortLabel';
  import Checkbox from '@material-ui/core/Checkbox';
  import FormControlLabel from '@material-ui/core/FormControlLabel';
  import Switch from '@material-ui/core/Switch';
  import DeleteIcon from '@material-ui/icons/Delete';
  import FilterListIcon from '@material-ui/icons/FilterList';

  const styles = (theme) => ({
    paper: {
      // maxWidth: 0,
      borderRadius: 0,
      margin: 'auto',
      overflow: 'hidden',
    },
    searchBar: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: 'block',
    },
    addUser: {
      marginRight: theme.spacing(1),
    },
    contentWrapper: {
      margin: '40px 16px',
    },
    kenteken: {
      width: '85px',
      height: '24px',
      padding: '0',
      borderRadius: '4px',
      position: 'relative',
      background: 'url(./kenteken_start.jpg) no-repeat 0px 0px #ffdc63',
      backgroundSize: 'auto calc(100% + 1px)',
      margin: '0',
      border: '1px solid #000',
      textAlign: 'left',
      paddingLeft: '15px',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      paddingRight: '4px',
      lineHeight: '22px'
    }
  });

  function createData(id, date, driver, kenteken, starttime, endtime, amount) {
    return { id, date, driver, kenteken, starttime, endtime, amount };
  }

  let rows = [];

  const formatDate = (value) => {
    var d = new Date(value)
    return ("" + d.getDate()).padStart(2, '0') + '-' +
      ("" + (d.getMonth() + 1)).padStart(2, '0') + '-' +
      ("" + (d.getYear() + 1900)).padStart(2, '0');
  }

  const formatMoney = (value) => {
    return '€ ' + (value / 100).toFixed(2)
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }


  const headCells = [
    { id: 'id', numeric: false, disablePadding: false, label: '#' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Datum' },
    { id: 'driver', numeric: false, disablePadding: false, label: 'Chauffeur' },
    { id: 'kenteken', numeric: false, disablePadding: false, label: 'Kenteken', kenteken: true },
    { id: 'starttime', numeric: true, disablePadding: false, label: 'Starttijd' },
    { id: 'endtime', numeric: true, disablePadding: false, label: 'Eindtijd' },
    { id: 'amount', numeric: true, disablePadding: false, label: 'Bedrag' },
  ];


  // Datum	Chauffeur	Kenteken	 	Begintijd	Eindtijd	Pauzetijd	Gewerkte Uren	 	Bedrag	Pin	Rekening	Contant
  const headCells1 = [
    { id: 'Datum', numeric: false, disablePadding: false, label: 'Datum' },
    { id: 'Chauffeur', numeric: false, disablePadding: false, label: 'Chauffeur' },
    { id: 'Kenteken', numeric: false, disablePadding: false, label: 'Kenteken', kenteken: true },
    { id: 'Begintijd', numeric: true, disablePadding: false, label: 'Begintijd' },
    { id: 'Eindtijd', numeric: true, disablePadding: false, label: 'Eindtijd' },
    { id: 'Pauzetijd', numeric: true, disablePadding: false, label: 'Pauzetijd' },
    { id: 'GewerkteUren', numeric: true, disablePadding: false, label: 'GewerkteUren' },
    { id: 'Bedrag', numeric: true, disablePadding: false, label: 'Bedrag' },
    { id: 'Pin', numeric: true, disablePadding: false, label: 'Pin' },
    { id: 'Rekening', numeric: true, disablePadding: false, label: 'Rekening' },
    { id: 'Contant', numeric: true, disablePadding: false, label: 'Contant' },
  ];


  const headCells2 = [
    { id: 'description', numeric: false, disablePadding: false, label: 'Omschrijving' },
    { id: 'Aanslagen', numeric: false, disablePadding: false, label: 'Aanslagen' },
    { id: 'Eenheden', numeric: false, disablePadding: false, label: 'Eenheden' },
    { id: 'TotaleKm', numeric: false, disablePadding: false, label: 'Totale KM' },
    { id: 'BezetteKm', numeric: true, disablePadding: false, label: 'Bezette KM' },
    { id: 'Kilometerstand', numeric: true, disablePadding: false, label: 'Kilometerstand' }
  ];

  const headCells3 = [
    { id: 'StartTijd', numeric: false, disablePadding: false, label: 'StartTijd' },
    { id: 'StartKm', numeric: false, disablePadding: false, label: 'StartKm' },
    { id: 'Van', numeric: false, disablePadding: false, label: 'Van' },
    { id: 'Naar', numeric: false, disablePadding: false, label: 'Naar' },
    { id: 'EndKm', numeric: true, disablePadding: false, label: 'EndKm' },
    { id: 'EndTijd', numeric: true, disablePadding: false, label: 'EndTijd' },
    { id: 'Bedrag', numeric: true, disablePadding: false, label: 'Bedrag' },
    { id: 'Pin', numeric: true, disablePadding: false, label: 'Pin' },
    { id: 'Rekening', numeric: true, disablePadding: false, label: 'Rekening' },
  ];

  const headCells4 = [
    { id: 'id', numeric: false, disablePadding: false, label: '#' },
    { id: 'StartTijd', numeric: false, disablePadding: false, label: 'Pauze van' },
    { id: 'EndTijd', numeric: false, disablePadding: false, label: 'Pauze tot' },
  ];

  function Rides(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const [selectedRide, setSelectedRide] = useState(null);

    const sortedData = window.data.slice().sort((a, b) => Date.parse(b.Datum) - Date.parse(a.Datum))
    rows = [];
    var i = 1;
    sortedData.forEach(d => {
      rows.push(createData(i++, formatDate(d.Datum), d.Chauffeur	, d.Kenteken	, d.Begintijd	, d.Eindtijd	, '€ ' + (d.Bedrag / 100).toFixed(2)))
    })

    window.setSelectedRide = setSelectedRide;

    const onClick = (index) => {
      setSelectedRide(sortedData[index])
    }

    if (selectedRide) {
      const rows1 = [JSON.parse(JSON.stringify(selectedRide))];
      rows1[0].Datum = formatDate(rows1[0].Datum);
      rows1[0].Bedrag = formatMoney(rows1[0].Bedrag);
      rows1[0].Pin = formatMoney(rows1[0].Pin);
      rows1[0].Rekening = formatMoney(rows1[0].Rekening);
      rows1[0].Contant = formatMoney(rows1[0].Contant);

      const rows2 = [{
        description: 'Beginstanden:',
        Aanslagen: selectedRide.Aanslagen[0],
        Eenheden: selectedRide.Eenheden[0],
        TotaleKm: selectedRide.TotaleKm[0],
        BezetteKm: selectedRide.BezetteKm[0],
        Kilometerstand: selectedRide.Kilometerstand[0]
      },{
        description: 'Eindstanden:',
        Aanslagen: selectedRide.Aanslagen[1],
        Eenheden: selectedRide.Eenheden[1],
        TotaleKm: selectedRide.TotaleKm[1],
        BezetteKm: selectedRide.BezetteKm[1],
        Kilometerstand: selectedRide.Kilometerstand[1]
      },{
        description: 'Verschil :',
        Aanslagen: selectedRide.Aanslagen[1] - selectedRide.Aanslagen[0],
        Eenheden: selectedRide.Eenheden[1] - selectedRide.Eenheden[0],
        TotaleKm: selectedRide.TotaleKm[1] - selectedRide.TotaleKm[0],
        BezetteKm: selectedRide.BezetteKm[1] - selectedRide.BezetteKm[0],
        Kilometerstand: selectedRide.Kilometerstand[1] - selectedRide.Kilometerstand[0],
      }];

      const rows3 = []
      selectedRide.Rides.forEach(r => {
        rows3.push({
          StartTijd: r.StartTijd,
          StartKm: r.StartKm,
          Van: r.Van,
          Naar: r.Naar,
          EndKm: r.EndKm,
          EndTijd: r.EndTijd,
          Bedrag: formatMoney(r.Bedrag),
          Pin: formatMoney(r.Pin),
          Rekening: formatMoney(r.Rekening)
        })
      });

      const rows4 = []
      let i = 1;
      selectedRide.Pauses.forEach(r => {
        rows4.push({
          id: i++,
          StartTijd: r.StartTijd,
          EndTijd: r.EndTijd,
        })
      });

      return (
        <>
        <Paper className={classes.paper}>
            <Toolbar>
              <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                Dienst
              </Typography>
            </Toolbar>
            <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headCells1.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows1.map((row) => (
                  <TableRow key={row.name} style={{ cursor: 'pointer' }}>
                    {headCells1.map((headCell, i) => (
                      <TableCell
                        key={`row_${row.id}_${headCell.id}`}
                        scope="rpw"
                        compoent="th"
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        {headCell.kenteken ? <div className={classes.kenteken}>{row[headCell.id]}</div> : row[headCell.id] }
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
        </Paper>
        <Paper className={classes.paper} style={{marginTop:20}}>
            <Toolbar>
              <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
              Meterstanden
              </Typography>
            </Toolbar>
            <TableContainer component={Paper}>
            <Table size="small" className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headCells2.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows2.map((row) => (
                  <TableRow key={row.name} style={{ cursor: 'pointer' }}>
                    {headCells2.map((headCell, i) => (
                      <TableCell
                        key={`row_${row.id}_${headCell.id}`}
                        scope="rpw"
                        compoent="th"
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        {headCell.kenteken ? <div className={classes.kenteken}>{row[headCell.id]}</div> : row[headCell.id] }
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Paper className={classes.paper} style={{marginTop:20}}>
            <Toolbar>
              <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                Ritten
              </Typography>
            </Toolbar>
            <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headCells3.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows3.map((row) => (
                  <TableRow key={row.name}>
                    {headCells3.map((headCell, i) => (
                      <TableCell
                        key={`row_${row.id}_${headCell.id}`}
                        scope="rpw"
                        compoent="th"
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        {headCell.kenteken ? <div className={classes.kenteken}>{row[headCell.id]}</div> : row[headCell.id] }
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
        </Paper>
        <Paper className={classes.paper} style={{marginTop:20}}>
            <Toolbar>
              <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                Pauzes
              </Typography>
            </Toolbar>
            <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headCells4.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows4.map((row) => (
                  <TableRow key={row.name} style={{ cursor: 'pointer' }}>
                    {headCells4.map((headCell, i) => (
                      <TableCell
                        key={`row_${row.id}_${headCell.id}`}
                        scope="rpw"
                        compoent="th"
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        {headCell.kenteken ? <div className={classes.kenteken}>{row[headCell.id]}</div> : row[headCell.id] }
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
        </Paper>
        </>
      );

    }

    return (
      <Paper className={classes.paper}>
        {<AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <SearchIcon className={classes.block} color="inherit" />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Zoeken"
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                />
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" className={classes.addUser}>
                  Rit toevoegen
                </Button>
                <Tooltip title="Herladen">
                  <IconButton>
                    <RefreshIcon className={classes.block} color="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>}
          <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                      {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow hover key={row.name} style={{ cursor: 'pointer' }} onClick={() => onClick(index)}>
                  {headCells.map((headCell, i) => (
                    <TableCell
                      key={`row_${row.id}_${headCell.id}`}
                      scope="rpw"
                      compoent="th"
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      {headCell.kenteken ? <div className={classes.kenteken}>{row[headCell.id]}</div> : row[headCell.id] }
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  Rides.propTypes = {
    classes: PropTypes.object.isRequired,
  };

  export default withStyles(styles)(Rides);