import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';


// Set up our HTTP request
var xhr = new XMLHttpRequest();

// Setup our listener to process compeleted requests
xhr.onreadystatechange = function () {

	// Only run if the request is complete
	if (xhr.readyState !== 4) return;

	// Process our return data
	if (xhr.status >= 200 && xhr.status < 300) {
    // What do when the request is successful
		window.data = JSON.parse(xhr.responseText)
		window.data.forEach(session => {
			let bedrag = 0;
			let pin = 0;
			let contant = 0;
			let rekening = 0;
			session.Rides.forEach(rid => {
				rid.Bedrag = parseInt(rid.Bedrag.toString().replace(/[^0-9]/gi, '')) || 0;
				rid.Pin = parseInt(rid.Pin.toString().replace(/[^0-9]/gi, '')) || 0;
				rid.Rekening = parseInt(rid.Rekening.toString().replace(/[^0-9]/gi, '')) || 0;

				bedrag += rid.Bedrag;
				pin += rid.Pin;
				rekening += rid.Rekening;
			})
			contant = bedrag - pin - rekening;
			session.Bedrag = bedrag
			session.Pin = pin
			session.Rekening = rekening
			session.contant = contant
		})
    ReactDOM.render(<App />, document.getElementById('app'));
	}

};

// Create and send a GET request
// The first argument is the post type (GET, POST, PUT, DELETE, etc.)
// The second argument is the endpoint URL
xhr.open('GET', 'https://beheer.taxi050.nl/session.php');
xhr.send();
