
  import React from 'react';
  import PropTypes from 'prop-types';
  import AppBar from '@material-ui/core/AppBar';
  import Toolbar from '@material-ui/core/Toolbar';
  import Typography from '@material-ui/core/Typography';
  import Paper from '@material-ui/core/Paper';
  import Grid from '@material-ui/core/Grid';
  import Button from '@material-ui/core/Button';
  import TextField from '@material-ui/core/TextField';
  import Tooltip from '@material-ui/core/Tooltip';
  import IconButton from '@material-ui/core/IconButton';
  import { withStyles } from '@material-ui/core/styles';
  import SearchIcon from '@material-ui/icons/Search';
  import RefreshIcon from '@material-ui/icons/Refresh';
  import AddIcon from '@material-ui/icons/Add';
  import clsx from 'clsx';
  import { lighten, makeStyles } from '@material-ui/core/styles';
  import Table from '@material-ui/core/Table';
  import TableBody from '@material-ui/core/TableBody';
  import TableCell from '@material-ui/core/TableCell';
  import TableContainer from '@material-ui/core/TableContainer';
  import TableHead from '@material-ui/core/TableHead';
  import TablePagination from '@material-ui/core/TablePagination';
  import TableRow from '@material-ui/core/TableRow';
  import TableSortLabel from '@material-ui/core/TableSortLabel';
  import Checkbox from '@material-ui/core/Checkbox';
  import FormControlLabel from '@material-ui/core/FormControlLabel';
  import Switch from '@material-ui/core/Switch';
  import DeleteIcon from '@material-ui/icons/Delete';
  import FilterListIcon from '@material-ui/icons/FilterList';

  const styles = (theme) => ({
    paper: {
      // maxWidth: 0,
      borderRadius: 0,
      margin: 'auto',
      overflow: 'hidden',
    },
    searchBar: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: 'block',
    },
    addUser: {
      marginRight: theme.spacing(1),
    },
    contentWrapper: {
      margin: '40px 16px',
    },
  });

  function createData(month, hours, amount, pin, rekening, ingeleverd, wassen, tanken, overige, contanteOmzet) {
    return { month, hours, amount, pin, rekening, ingeleverd, wassen, tanken, overige, contanteOmzet };
  }

  const rows = [
    createData('Januari','0 Uren','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0'),
    createData('Februari','0 Uren','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0'),
    createData('Maart','0 Uren','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0'),
    createData('April','0 Uren','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0'),
    createData('Mei','0 Uren','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0'),
    createData('Juni','0 Uren','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0'),
    createData('Juli','0 Uren','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0'),
    createData('Augustus','232.90 Uren','€ 3829.32','€ 1975.45','€ 132.05','€ 1721.82','€ 5.90','€ 272.87','€ 0.00','€ 1721.82'),
    createData('September','209.60 Uren','€ 4488.23','€ 1737.17','€ 44.36','€ 2706.70','€ 53.95','€ 281.68','€ 0.00','€ 2706.70'),
    createData('Oktober','258.10 Uren','€ 1474.37','€ 423.20','€ 175.00','€ 876.17','€ 12.00','€ 173.64','€ 0.00','€ 876.17'),
    createData('November','223.60 Uren','€ 1080.10','€ 359.30','€ 3.25','€ 717.55','€ 2.17','€ 96.11','€ 0.00','€ 717.55'),
    createData('December','0 Uren','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0','€ 0'),
  ];

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    { id: 'month', numeric: false, disablePadding: false, label: 'Maand' },
    { id: 'hours', numeric: false, disablePadding: false, label: 'Gewerkte uren' },
    { id: 'amount', numeric: true, disablePadding: false, label: 'Bedrag' },
    { id: 'pin', numeric: true, disablePadding: false, label: 'Pin' },
    { id: 'rekening', numeric: true, disablePadding: false, label: 'Rekening' },
    { id: 'ingeleverd', numeric: true, disablePadding: false, label: 'Ingeleverd' },
    { id: 'wassen', numeric: true, disablePadding: false, label: 'Wassen' },
    { id: 'tanken', numeric: true, disablePadding: false, label: 'Tanken' },
    { id: 'overige', numeric: true, disablePadding: false, label: 'Overige' },
    { id: 'contanteOmzet', numeric: true, disablePadding: false, label: 'Contante omzet' },
  ];


  function Totals(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <Paper className={classes.paper}>
        {<AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <SearchIcon className={classes.block} color="inherit" />
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Zoeken"
                  InputProps={{
                    disableUnderline: true,
                    className: classes.searchInput,
                  }}
                />
              </Grid>
              <Grid item>
                <Tooltip title="Herladen">
                  <IconButton>
                    <RefreshIcon className={classes.block} color="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>}
          <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  {headCells.map((headCell, i) => (
                    <TableCell
                      key={`row_${row.id}_${headCell.id}`}
                      scope="rpw"
                      compoent="th"
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      {row[headCell.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  Totals.propTypes = {
    classes: PropTypes.object.isRequired,
  };

  export default withStyles(styles)(Totals);