import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import PermMediaOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActual';
import PublicIcon from '@material-ui/icons/Public';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import TimerIcon from '@material-ui/icons/Timer';
import SettingsIcon from '@material-ui/icons/Settings';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import TrafficIcon from '@material-ui/icons/Traffic';
import TheatersIcon from '@material-ui/icons/Theaters';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import EuroIcon from '@material-ui/icons/Euro';

const categories = [
  {
    id: 'Taxi',
    children: [
      { id: 'Rides', icon: <TheatersIcon /> },
      { id: 'Costs', icon: <EuroIcon /> },
      { id: 'Vehicals', icon: <LocalTaxiIcon />},
      { id: 'DailyHours', icon: <QueryBuilderIcon />, active: true },
      { id: 'Totals', icon: <EqualizerIcon />},
      // { id: 'Hosting', icon: <PublicIcon /> },
      // { id: 'Functions', icon: <SettingsEthernetIcon /> },
      // { id: 'ML Kit', icon: <SettingsInputComponentIcon /> },
    ],
  },
  {
    id: 'Gebruiker',
    children: [
      { id: 'Settings', icon: <SettingsIcon /> },
      { id: 'Logout', icon: <MeetingRoomIcon /> },
      // { id: 'Test Lab', icon: <PhonelinkSetupIcon /> },
    ],
  },
];

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
});

function Navigator(props) {
  const { classes, onSelectionChanges, ...other } = props;
  const [selectedchildId, setSelectedChildId] = React.useState('Rides');

  const onClick = (index) => {
    if (window.setSelectedRide) {
      window.setSelectedRide(null)
    }
    setSelectedChildId(index)
    onSelectionChanges(index)
  };

  if (window.localStorage.getItem('loggedIn') !== '1') {
    return (<div />)
  }

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          Ritregistratie
        </ListItem>
        <ListItem className={clsx(classes.item, classes.itemCategory)}>
          <ListItemIcon className={classes.itemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            Taxi050
          </ListItemText>
        </ListItem>
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active }, i) => (
              <ListItem
                key={childId}
                button
                onClick={(e) => onClick(childId)}
                className={clsx(classes.item, childId === selectedchildId && classes.itemActiveItem)}
              >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>
            ))}

            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectionChanges: PropTypes.func.isRequired
};

export default withStyles(styles)(Navigator);